<template>
  <Layout two-headers>
    <template #header-top>
      <PageHeaderTopNew />
    </template>
    <template #body>
      <div class="container py-[40px] h-full md:h-auto">
        <CheckTemplate
          :current-respondent="getSelectedRespondentForVerification"
          :respondents-evaluating="getRespondentsEvaluating"
          :respondents-evaluated="getRespondentsEvaluated"
          :steps="getStepsVerify"
        />
      </div>
    </template>
    <template #header>
      <PageHeader />
    </template>
  </Layout>
</template>

<script setup>
import Layout from '@/components/layout/Layout.vue'
import PageHeaderTopNew from '@/components/layout/PageHeaderTopNew.vue'
import PageHeader from '@/components/layout/PageHeader.vue'
import CheckTemplate from '@/components/CheckTemplate.vue'

import { useGetters } from '@/store/useStore'

const {
  getSelectedRespondentForVerification,
  getRespondentsEvaluating,
  getRespondentsEvaluated,
  getStepsVerify,
} = useGetters()
</script>


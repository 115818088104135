<template>
<div class="relative h-full">
  <div 
    :style="{
      'grid-template-columns': `${props.steps.evaluating ? '34% 8% ' : ''} 16% ${props.steps.evaluated ? '8% 34%' : ''}`
    }"
    class="grid h-full items-center justify-center md:!block md:text-center"
  >
    <template v-if="props.steps.evaluating">
      <RespondentsListCheck
        :current-respondent="currentRespondent"
        :respondents="props.respondentsEvaluating"
        mode="evaluating"
      />
      <i 
        class="pi pi-chevron-right pi--next text-center md:rotate-90 md:my-[10px]" 
        style="font-size: 50px;"
        :style="{
          'color': getColor('evaluating')
        }"
      ></i>
    </template>

    <div class="relative flex flex-col items-center text-center">
      <div v-if="getAttributesReady" class="relative w-[120px] mb-[14px]">
        <PhotoUploader 
          v-if="getCurrentRespondent.respondentId === props.currentRespondent.respondentId" 
          :photo-url="getCurrentRespondent.photoUrl" 
        />
        <RespondentPhoto
          v-else
          :photo-url="props.currentRespondent.photoUrl"
        />
        <div 
          v-if="isRespondentVerified"
          class="absolute top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-darkgray w-[32px] h-[32px] leading-[32px] text-center text-[#ffffff]"
        >
          <i class="pi pi-check mt-[8px]" style="font-weight: bold;" />
        </div>
      </div>
      <h5 class="mb-1 text-22 font-medium text-darkgray leading-[1.2] dark:text-white">{{ props.currentRespondent?.fullName ?? '-.-' }}</h5>
      <!-- <span class="text-14 text-gray">{{ props.currentRespondent?.email ?? '-.-' }}</span> -->
    </div>

    <template v-if="props.steps.evaluated">
      <i 
        class="pi pi-chevron-right pi--next text-[#ccc] text-center md:rotate-90 md:my-[10px]" 
        style="font-size: 50px;"
        :style="{
          'color': getColor('evaluated')
        }"
      ></i>
      <RespondentsListCheck
        :current-respondent="currentRespondent"
        :respondents="props.respondentsEvaluated"
        mode="evaluated"
      />
    </template>
  </div>
</div>
</template>

<script setup>
import { computed, watch } from 'vue'

import { useGetters, useActions } from '@/store/useStore'
import { useVerify } from '@/use/useHelpers'

import RespondentsListCheck from '@/components/RespondentsListCheck.vue'
import PhotoUploader from '@/components/UI/PhotoUploader.vue'
import RespondentPhoto from '@/components/UI/RespondentPhoto.vue'

const props = defineProps({
  currentRespondent: Object,
  respondentsEvaluating: Array,
  respondentsEvaluated: Array,
  steps: Object,
})

const {
  getCurrentRespondent,
  getAttributesReady,
  getColor,
  getRespondentsForVerification,
} = useGetters()

const {
  fetchGetChoosingVerifyingRelations,
  fetchGetChildrenDepartmentsAndRespondents,
} = useActions()

const isPageVerify = useVerify()

const isRespondentVerified = computed(() => {
  const isCurrentRespondentVerified = getRespondentsForVerification.value.find(({ respondentId }) => props.currentRespondent.respondentId === respondentId)?.verified ?? false
  return isPageVerify && isCurrentRespondentVerified
})

watch(() => props.currentRespondent, async (newValue, prevValue) => {
  if (props.currentRespondent.respondentId && newValue?.respondentId !== prevValue?.respondentId) {
    await fetchGetChoosingVerifyingRelations(props.currentRespondent.respondentId)

    const departmentIds = {}

    const respondents = [...props.respondentsEvaluating, ...props.respondentsEvaluated]
    respondents.forEach(respondent => {
      respondent.departmentIds.forEach(departmentId => {
        departmentIds[departmentId] = true
      })
    })

    Object.keys(departmentIds).forEach(departmentId => fetchGetChildrenDepartmentsAndRespondents(departmentId))
  }
}, { immediate: true, deep: true })
</script>
